import React, { Component } from "react";
import ImageGallery from 'react-image-gallery';
import { MapPin, Smartphone, Mail } from 'react-feather'
import "react-image-gallery/styles/css/image-gallery.css";

export default class work extends Component {
  render() {
    const { data } = this.props;

    const buildImages = (images) => {
      const imageArray = [];
      images && images.map((item, index) => {
        imageArray.push({
          srcSet: item.fluid.srcSet,
          thumbnail: item.fluid.src
        });
      });
      return imageArray;
    };

    return (
      <div className="work section" id="Apartments">
        <div className="container">
          <div className="section-head">
            <h2 className="text-center">Apartments</h2>
          </div>
          <div className="row">

          {data.edges.map((item, index) => {
              return (
                <div className="row" id={index}>
                  <div className="col-sm-7 col-xs-12 order-3 order-md-0">
                    <ImageGallery 
                      className="image-gallery"
                      items={buildImages(item.node.images)}
                      showIndex={true}
                      thumbnailPosition={"bottom"}
                      slideDuration={450}
                      slideInterval={2000}
                      showNav={true}
                    />
                  </div>
                  <div className="image-gallery-col col-sm-3 col-xs-12">
                    <div className="row">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.node.description.childMarkdownRemark.html
                        }}
                      />
                    </div>
                    {/*<div className="row">
                      <div class="col-sm-12">
                        <form
                          style={{display: "inline-block"}}
                          name="apartments"
                          method="POST"
                          data-netlify="true"
                        >
                            <input type="email" name="email" class="form-control" placeholder="Enter email address" required />
                            <input type="hidden" name="ApartmentType" value={item.node.siteName} required />
                            <button class="btn btn-primary" type="submit">Enquire</button>
                            <input type="hidden" name="subject" value="Apartments" />
                            <input type="hidden" name="form-name" value="apartments" />
                        </form>
                      </div>
                      </div>*/}
                    <div className="row">
                      <div class="col-sm-12">
                      <a className="contact-details-item" href={`mailto:patrick@caisterlodge.co.za;david@caisterlodge.co.za;philip@caisterlodge.co.za?subject=Enquiry: ${item.node.siteName}`}>
                        <Mail /> Enquire - {item.node.siteName}
                      </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
