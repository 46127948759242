import React, { Component } from "react";
import Map from "./map";
import { MapPin, Smartphone, Mail } from 'react-feather'

export default class Contact extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="contact section" id="Contact">
        <div className="container">
          <div className="section-head">
            <h2 className="text-center">Contact</h2>
          </div>
          <div class="row">
            <div class="container column">  
              <div
                  dangerouslySetInnerHTML={{
                    __html: data.contactDetails.contactDetails
                  }} />
            </div>    
            <div class="container column">
              <div className="contact-googlemap">
                <iframe name="mapframe" width="100%" height="435" 
                  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCc3zoz5TZaG3w2oF7IeR-fhxNXi8uywNk&q=CAISTER+LODGE+RETIREMENT+COMPLEX">
                </iframe>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="container column">
              <div className="section-head">
                <h2 className="text-center">Enquire</h2>
              </div>
            <form
                //action={`https://formspree.io/${this.props.data}`}
                name="contact"
                method="POST"
                data-netlify="true"
              >
                <div>
                  <label>
                    Your Name: <input type="text" name="name" required />
                  </label>
                </div>
                <div>
                  <label>
                    Your Email: <input type="email" name="email" required />
                  </label>
                </div>
                <div>
                  <label>
                    Message: <textarea name="message" required></textarea>
                  </label>
                </div>
                <div>
                  <button type="submit">Send</button>
                </div>
                <input type="hidden" name="subject" value="Contact" />
                <input type="hidden" name="form-name" value="contact" />
              </form>
            </div>
            <div class="container column">
              {data.address && (
                  <a
                    className="contact-details-item"
                    href={`https://www.google.com.au/maps/search/${encodeURI(
                      data.address
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MapPin /> {data.address}
                  </a>
                )}
                {data.phoneNumber && (
                  <a className="contact-details-item" href={`tel:${data.phoneNumber}`}>
                    <Smartphone /> {data.phoneNumber}
                  </a>
                )}
                {data.faxNumber && (
                  <a className="contact-details-item" href={`tel:${data.faxNumber}`}>
                    <Smartphone /> {data.faxNumber}
                  </a>
                )}
                {data.email && (
                  <a className="contact-details-item" href={`mailto:${data.email}`}>
                    <Mail /> {data.email}
                  </a>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
