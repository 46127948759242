import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import BackToTop from "react-back-to-top-button";

import Banner from "../components/banner";
import About from "../components/about";
import Service from "../components/service";
import Apartments from "../components/apartments";
import Blogs from "../components/blogs";
import ActivitiesFacilities from "../components/activitiesFacilities";
import Contact from "../components/contact";
import Photos from "../components/photos";

const IndexPage = ({ data }) => (
  <Layout header="home">
    <SEO
      title={data.contentfulAbout.designation}
      keywords={[
        `Caister Lodge`, 
        `Luxury Retirement`, 
        `Retirement Lodge`, 
        `Durban Retirement`, 
        `Berea Retirement`, 
        `Care Centre`,
        `Retirement Apartments`,
        `Affordable Retirement`,
        `All Included Retirement`,
        `Retirement Activities`,
        `Retirement Home`
      ]}
    />
    <Banner data={data.contentfulAbout}></Banner>
      
    {data.contentfulSiteInformation.menus
      .filter(item => item === "About")
      .map(t => {
        return <About data={data.contentfulAbout}></About>;
      })}

    {data.contentfulSiteInformation.menus
      .filter(item => item === "Service")
      .map(t => {
        return <Service data={data.allContentfulService}></Service>;
      })}

    {data.contentfulSiteInformation.menus
      .filter(item => item === "Activities & Facilities")
      .map(t => {
        return (
          <ActivitiesFacilities data={data.allContentfulActivitiesFacilities}></ActivitiesFacilities>
        );
      })}

    {data.contentfulSiteInformation.menus
      .filter(item => item === "Apartments")
      .map(t => {
        return <Apartments data={data.allContentfulWorks}></Apartments>;
      })}

    {data.contentfulSiteInformation.menus
      .filter(item => item === "Blogs")
      .map(t => {
        return <Blogs data={data.allContentfulBlogs}></Blogs>;
      })}

    {data.contentfulSiteInformation.menus
      .filter(item => item === "Gallery")
      .map(t => {
        return <Photos data={data.contentfulPhotos}></Photos>;
      })}

    {data.contentfulSiteInformation.menus
      .filter(item => item === "Contact")
      .map(t => {
        return <Contact data={data.contentfulContact}></Contact>;
      })}

    <BackToTop
        showOnScrollUp
        showAt={100}
        speed={2000}
        easing="easeInOutQuint"
        children="&#8682;"
    />
  </Layout>
);

export default IndexPage;

export const pageQuery = graphql`
  query AboutQuery {
    contentfulAbout {
      name
      photo {
        file {
          url
        }
        fluid {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      designation
      age
      facebook
      github
      gmail
      id
      instagram
      linkdin
      twitter
      youtube
      location
      description {
        childMarkdownRemark {
          html
        }
      }
      bannerImage {
        fluid(maxWidth: 1500) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      bannerList
    }
    allContentfulService(sort: {fields: title, order: ASC}) {
      edges {
        node {
          title
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulBlogs(limit: 5, sort: {fields: date, order: DESC}) {
      edges {
        node {
          title
          slug
          date
          createdAt
        }
      }
    }
    allContentfulActivitiesFacilities(sort: {fields: name, order: ASC})  {
      edges {
        node {
          name
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulWorks(sort: {fields: sortOrder, order: ASC}) {
      edges {
        node {
          siteName
          url
          sortOrder
          image {
            fluid(maxWidth: 600) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
          images {
            fluid(maxWidth: 600) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    contentfulPhotos {
      photos {
        fluid(maxWidth: 600) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    contentfulSiteInformation {
      menus
    }
    contentfulContact {
      address
      email
      phoneNumber
      faxNumber
      contactDetails {
        contactDetails
      }
    }
  }
`;
